import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// import Footer from '../components/Footer/Footer'
import Footer from "../components/Footer/Footer";
import MainNav from "./MainNav";
import useSiteMetadata from "./SiteMetadata";
import smoothscroll from "smoothscroll-polyfill";
import { withPrefix } from "gatsby";
import "../css/common.scss";

const TemplateWrapper = ({
  children,
  NavBackgroundColor,
  NavFontColor,
  NavMenuIcon,
  NavIncLabLogo,
  footerFontColor,
  footerBackground,
  footerIcons,
  title,
  description,
}) => {
  // const { title, description } = useSiteMetadata()

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>TheIncLab | {title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img//favicon.ico`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img//favicon.ico`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img//favicon.ico`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img//favicon.ico`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-HSNNMGGQ4F"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-HSNNMGGQ4F');`}
        </script>
      </Helmet>
      <MainNav
        backgroundColor={NavBackgroundColor}
        menuIcon={NavMenuIcon}
        incLabLogo={NavIncLabLogo}
        NavFontColor={NavFontColor}
      />
      <div>{children}</div>
      <Footer
        fontColor={footerFontColor}
        background={footerBackground}
        icons={footerIcons}
      />
    </div>
  );
};

export default TemplateWrapper;
