import React from 'react'


import './footer.scss'

const footerData = {
    rowLinks: [
        { name: 'ABOUT US', link: '/about'},
        { name: 'CONTACT', link: '/contact'},  
         { name: 'VISIT REQUEST', link: '/visit'}, 
    ],
    contact: {
        headerTitle: 'Contact Us',
        addresses: [
            {
                location: 'TIL North - DC Metro',
                address: 'AI Advanced and Data Prototyping Lab',
                address: '8300 Greensboro Drive Suite 1040',
                state: 'McLean, VA',
                zip: '22102',
            },
            {
                location: 'TIL South - Tampa',
                address: 'Headquarters and DoD Software Factory',
                address: '1320 E 9th Ave Suite 100',
                state: 'Tampa, FL',
                zip: '33605',
            },
            
             {
                location: 'TIL Central - Nashville',
                address: 'Digital Engineering and Data Innovation Lab',
                address: '2222 Rosa L Parks Blvd',
                state: 'Nashville, TN',
                zip: '37228',
            },

        ],
        phoneNumbers: [
            {
                location: 'DC Metro',
                phone: ' +1 (212) 390-8111'
            },
            {
                location: 'Tampa',
                phone: ' +1 (813) 547-3558'
            },

        ],
        email: {
            address: 'contact@theinclab.com'
        }
    }
}


const Footer = ({
    fontColor='black', 
    background='none', 
    icons={
        youtube: '/img/footer/youtube-black.png', 
        linkedin: '/img/footer/linkedin-black.png'
    } 
}) => {




    return (
        <div className='footer-main-wrapper' style={{color: fontColor, background: background}}>
            
            <div className='main-content-rows' style={{borderTop: `solid thin ${fontColor}`,
                borderBottom: `solid thin ${fontColor}`}}> 

                <div className='row-one'>
                    <div className='row-item'> 
                        <a href='/'>
                        <img src='/img/footer/til-square.png' />
                        </a>
                    </div>

                    {
                    footerData.rowLinks.map(listItem =><a style={{color: fontColor}} href={listItem.link}>  <div className='row-item'> {listItem.name}</div> </a>
                             )
                    }
                </div>
               
                
                <div className='row-two'>
                    <div className='social-icons'>
                        <a target='new' href='https://www.linkedin.com/company/the-inc-lab/mycompany/'> <img src={icons.linkedin}/></a>
                        <a target='new' href='https://www.youtube.com/channel/UCjGbOzdk1KPFNdvMK0ALp1g'> <img src={icons.youtube}/></a>
                                          </div>
                </div>
    
            </div>


            <div className='sub-row'>
                <div className='left-section'>
                        <div className='legal-links'>
                         <a style={{color: fontColor}}  href='/legal/privacy'>Privacy Policy </a>
                         <a style={{color: fontColor}} href='/legal/terms'> Terms & Conditions</a>
                         <a style={{color: fontColor}} href='/legal/cookies'> Cookie Policy </a>
                        </div>
                </div>
                <div className='right-section'>
    			 © {new Date().getFullYear()}  TheIncLab.				 
                </div>
            </div>
           

        </div>
    )
}


export default Footer
