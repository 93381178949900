import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { motion, useAnimation } from 'framer-motion'
import './mainNav.scss'


export const MobileNavLinks = [
    {
    text: 'HOME BASE',
    link: '/',
    subMenuItems: []
    }, 
{
 text: 'INDUSTRIES',
 link: '/industries',
 subMenuItems: [
    { name: 'AEROSPACE', link: '/industries/aerospace'},
    { name: 'DEFENSE', link: '/industries/defense'},
    { name: 'CONSUMER GOODS ', link: '/industries/consumer-goods'},
    { name: 'HEALTH & LIFE SCIENCES', link: '/industries/health-and-life-sciences'},
    { name: 'INTELLIGENCE', link: '/industries/intelligence'},
    { name: 'CYBERSECURITY', link: '/industries/cybersecurity'},
    { name: 'CONSUMER ELECTRONICS', link: '/industries/consumer-electronics'},
    { name: 'TRANSPORTATION', link: '/industries/transportation'},
    { name: 'FINANCE', link: '/industries/finance'},
 ]
}, 
{
text: 'CAPABILITIES',
link: '/capabilities',
subMenuItems: [
    { name: 'AI & MACHINE LEARNING', link: '/capabilities/ai-technologies'},
    { name: 'RAPID PROTOTYPING', link: '/capabilities/rapid-prototyping'},
    { name: 'DISRUPTIVE TECHNOLOGIES', link: '/capabilities/disruptive-technologies'},
    { name: 'USER CENTERED DESIGN', link: '/capabilities/user-centered-design'},
    { name: 'ADVANCED 3D', link: '/capabilities/advanced-3d'},
    { name: 'SOFTWARE DEVELOPMENT', link: '/capabilities/software-development'},
    { name: 'EMERGING TECHNOLOGIES', link: '/capabilities/emerging-tech-engineering'},
    ]
}, 
{
text: 'WORK',
link: '/work',
subMenuItems: [
    { name: 'punch', link: '/work/punch'},
    { name: 'Samsung', link: '/work/samsung'},
    { name: 'Colombia', link: '/work/colombia'},
    { name: 'Dyssco', link: '/work/dyssco'},
    { name: 'Moon', link: '/work/moon'},
    { name: 'Nuvasive', link: '/work/nuvasive'},
]
}, 
{ 
text: 'About',
link: '/about',
subMenuItems: []
}, 
{
    text: 'Careers',
    link: '/careers',
    subMenuItems: []
}, 
{
text: 'Contact Us',
link: '/contact',
subMenuItems: []
}, 
{
    text: 'Articles',
    link: '/blog',
    subMenuItems: []
    }, 
]


const MobileNav = ({backgroundColor= '#FBFBFB', NavFontColor='black', menuIcon='/img/shared/MenuBarsBlack.png', incLabLogo='/img/shared/BlackTILtext.png' }) => {
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [showMegaMenu, setShowMegaMenu] = useState(false)
    const [selectedSubMenu, setSelectedSubMenu] = useState(null)

    const [showCapabilitiesList, setShowCapabilitiesList] = useState(false)
    const [showIndustriesList, setShowIndustriesList ] = useState(false)

    const slideInMegaMenuAnimation = {
        initial: {y: -300, opacity: 0 },
        animate: { y: 0, opacity: 1 },
      };

      const slideInSubMenuAnimation = {
        initial: {height: '0px', opacity: 0 },
        animate: { height: 'auto', opacity: 1 },
      };


    //   const fireSelectedSubMenu = (index) => {
    //       const list = MobileNavLinks[index].subMenuItems

    //     setShowSubMenu(!showSubMenu)
    //     setSelectedSubMenu(list)
    //   }

    const fireSelectedSubMenu = (listName) => {
        if(listName === 'capabilities'){
            setShowIndustriesList(false)
            setShowCapabilitiesList(!showCapabilitiesList)
        }else if(listName === 'industries'){
            setShowCapabilitiesList(false)
            setShowIndustriesList(!showIndustriesList)
        }
        

    //   setShowSubMenu(!showSubMenu)
    //   setSelectedSubMenu(list)
    }




      const toggleMegaMenu = () => {
          setSelectedSubMenu(null)
          setShowMegaMenu(!showMegaMenu)
      }


     

    return (
        <>
        <div className='main-nav-wrapper-mobile' style={{background: backgroundColor}}>
            <div className='nav-icon-wrapper' onClick={() => toggleMegaMenu()}>
                    <div> </div>
                    <div><img src={menuIcon}/></div>
            </div>

            <div className='centered-logo'>
               <a href='/'> <img src={incLabLogo} /> </a>
            </div>

            <motion.div className='slide-in-nav-wrapper'
            variants={slideInMegaMenuAnimation}
            initial='initial'
            animate={showMegaMenu ? "animate" : "initial"}
            transition={{ 
                type: 'spring',
                stifness: 50 
                // duration: .5
            }}
            >

            <div className='main-hub-links-wrapper' style={{background: 'none'}}>  

                <div className='nav-item' style={{color: NavFontColor, borderTop: NavFontColor }} onClick={() => fireSelectedSubMenu('capabilities')} >
                   {showCapabilitiesList ? <a href='/capabilities'>Capabilities</a> : 'Capabilities' }  
                {showCapabilitiesList ? <ExpandLessIcon/> : <ExpandMoreIcon/>} 
                </div>
                            
                    <motion.div className='sub-links-wrapper-mobile'
                    // style={{display: showCapabilitiesList ? 'block' : 'none'}}
                        variants={slideInSubMenuAnimation}
                        initial='initial'
                        animate={showCapabilitiesList  ? "animate" : "initial"}
                            transition={{ 
                                duration: .7,
                                type: 'spring',
                            }}
                        >
                <div className='sub-menu-list' style={{display: showCapabilitiesList ? 'block' : 'none'}}>
                        <div className='list-item'>
                           <a href='/capabilities/ai-technologies'>  AI & Machine Learning </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/emerging-tech-engineering'>   Emerging Tech Engineering </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/rapid-prototyping'>    Rapid Prototyping </a>
                        </div>

                        <div className='list-item'>
                           <a href='/capabilities/disruptive-technologies'>   Disruptive Technologies </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/user-centered-design'>   User Centered Design </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/intelligent-systems-design'>   Intelligent System Design </a>
                        </div>

                        <div className='list-item'>
                           <a href='/capabilities/data-fusion'>   Data Fusion </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/software-development'>    Software Development </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/advanced-3d'>   Advanced 3D </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/xr-collaboration'> XR Collaboration  </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/edge-technology'> Edge Technology  </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/digital-engineering'> Digital Engineering </a>
                        </div>
                    </div>

        </motion.div>

        <div className='nav-item' style={{color: NavFontColor, borderTop: NavFontColor }} onClick={() => fireSelectedSubMenu('industries')}> 
        {showIndustriesList ? <a href='/industries'>Industries</a> : 'Industries'}
        {showIndustriesList ? <ExpandLessIcon/> : <ExpandMoreIcon/>} </div>

                <motion.div className='sub-links-wrapper-mobile'
                    variants={slideInSubMenuAnimation}
                    initial='initial'
                    animate={showIndustriesList  ? "animate" : "initial"}
                        transition={{ 
                            duration: .7,
                            type: 'spring',
                        }}
                >
                        <div className='sub-menu-list' style={{display: showIndustriesList ? 'block' : 'none'}}>
                            <div className='list-item'>
                            <a href='/industries/defense'>  Defense </a>
                            </div>
                            <div className='list-item'>
                            <a href='/industries/consumer-goods'>   Consumer Goods </a>
                            </div>
                            <div className='list-item'>
                            <a href='/industries/health-and-life-sciences'> Health & Life Sciences </a>
                            </div>

                            <div className='list-item'>
                            <a href='/industries/intelligence'> Intelligence </a>
                            </div>
                            <div className='list-item'>
                            <a href='/industries/cybersecurity'> Cybersecurity</a>
                            </div>
                            <div className='list-item'>
                            <a href='/industries/consumer-electronics'>  Consumer Electronics </a>
                            </div>

                            <div className='list-item'>
                            <a href='/industries/aerospace'> Aerospace </a>
                            </div>
                            <div className='list-item'>
                            <a href='/industries/transportation'>  Transportation </a>
                            </div>
                            <div className='list-item'>
                            <a href='/industries/finance'>  Finance </a>
                            </div>
            </div>
        </motion.div>


        <a style={{color: NavFontColor, borderTop: NavFontColor }} href='/careers'> <div className='nav-item'>  Careers </div> </a> 
        <a style={{color: NavFontColor, borderTop: NavFontColor }} href='/work'> <div className='nav-item'> Work  </div></a>

            </div>

                {/* <div className='main-hub-links-wrapper'>
                    {
                        MobileNavLinks.map((item, index) => <div key={item.name} className='link-group'> 
                            <div className='icon-item' onClick={() => fireSelectedSubMenu(index)}>
                                {item.subMenuItems.length  ?  item.subMenuItems === selectedSubMenu  ? <RemoveIcon style={{color: item.subMenuItems === selectedSubMenu ? "#00A6CB" : ""}} /> : <AddIcon style={{color: item.subMenuItems === selectedSubMenu ? "#00A6CB" : ""}} />  : <div> </div>}
                            </div>
                            <div className='link'>
                                <a href={item.link}>{item.text}</a>
                            </div>
                        </div>  
    
                    )}
                </div> */}

                {/* <motion.div className='sub-links-wrapper'
                variants={slideInSubMenuAnimation}
                initial='initial'
                animate={showSubMenu || selectedSubMenu ? "animate" : "initial"}
                    transition={{ 
                        type: 'spring',
                        stifness: 50 
                    }}
                >
                    { selectedSubMenu && selectedSubMenu.map(item => <a key={item.name} href={item.link}>{item.name}</a>)}
                </motion.div> */}


            </motion.div>
        </div>
        </>
    )
}



export default MobileNav
