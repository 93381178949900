import React from 'react'
import DesktopNav from './desktopNav'
import MobileNav from './mobileNav'

 const MainNav = ({backgroundColor,
    menuIcon,
    incLabLogo,
    NavFontColor }) => {
    return (
        <div style={{background: backgroundColor, width:'100%', height: '57px'}}>
            <DesktopNav 
            backgroundColor={backgroundColor}
            menuIcon={menuIcon}
            incLabLogo={incLabLogo}
            NavFontColor={NavFontColor}
            />

            <MobileNav 
            backgroundColor={backgroundColor}
            menuIcon={menuIcon}
            incLabLogo={incLabLogo}
            />
        </div>
    )
}


export default MainNav;
