import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { motion, useAnimation } from 'framer-motion'
import './mainNav.scss'


export const mainNavLinks = [
    {
    text: 'HOME BASE',
    link: '/',
    subMenuItems: []
    }, 
{
 text: 'INDUSTRIES',
 link: '/industries/',
 subMenuItems: [
    { name: 'AEROSPACE', link: '/industries//aerospace'},
    { name: 'DEFENSE', link: '/industries//defense'},
    { name: 'CONSUMER GOODS ', link: '/industries//consumer-goods'},
    { name: 'HEALTH & LIFE SCIENCES', link: '/industries//health-and-life-sciences'},
    { name: 'INTELLIGENCE', link: '/industries//intelligence'},
    { name: 'CYBERSECURITY', link: '/industries//cybersecurity'},
    { name: 'CONSUMER ELECTRONICS', link: '/industries//consumer-electronics'},
    { name: 'TRANSPORTATION', link: '/industries//transportation'},
    { name: 'FINANCE', link: '/industries//finance'},
 ]
}, 
{
text: 'CAPABILITIES',
link: '/capabilities',
subMenuItems: [
    { name: 'AI & MACHINE LEARNING', link: '/capabilities/ai-technologies'},
    { name: 'RAPID PROTOTYPING', link: '/capabilities/rapid-prototyping'},
    { name: 'DISRUPTIVE TECHNOLOGIES', link: '/capabilities/disruptive-technologies'},
    { name: 'USER CENTERED DESIGN', link: '/capabilities/user-centered-design'},
    { name: 'ADVANCED 3D', link: '/capabilities/advanced-3d'},
    { name: 'SOFTWARE DEVELOPMENT', link: '/capabilities/software-development'},
    { name: 'EMERGING TECHNOLOGIES', link: '/capabilities/emerging-tech-engineering'},
    ]
}, 
{
text: 'WORK',
link: '/work',
subMenuItems: [
    { name: 'punch', link: '/work/punch'},
    { name: 'Samsung', link: '/work/samsung'},
    { name: 'Colombia', link: '/work/colombia'},
    { name: 'Dyssco', link: '/work/dyssco'},
    { name: 'Moon', link: '/work/moon'},
    { name: 'Nuvasive', link: '/work/nuvasive'},
]
}, 
{ 
text: 'About',
link: '/about',
subMenuItems: []
}, 
{
    text: 'Careers',
    link: '/careers',
    subMenuItems: []
}, 
{
text: 'Contact Us',
link: '/contact',
subMenuItems: []
}, 
{
    text: 'Articles',
    link: '/blog',
    subMenuItems: []
    }, 
]


const DesktopNav = ({backgroundColor= '#FBFBFB', NavFontColor='black',  menuIcon='/img/shared/MenuBarsBlack.png', incLabLogo='/img/shared/BlackTILtext.png' }) => {
 
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [showCapabilitiesList, setShowCapabilitiesList] = useState(false)
    const [showIndustriesList, setShowIndustriesList ] = useState(false)
    const [selectedSubMenu, setSelectedSubMenu] = useState(null)

    const slideInMegaMenuAnimation = {
        initial: {x: -300, opacity: 0 },
        animate: { x: 0, opacity: 1 },
      };

      const slideInSubMenuAnimation = {
        initial: {y: -1000, opacity: 0 },
        animate: { y: 0, opacity: 1 },
      };


      const fireSelectedSubMenu = (listName) => {
         
        if(listName === 'capabilities'){
            setShowIndustriesList(false)
            setShowCapabilitiesList(!showCapabilitiesList)
        }else if(listName === 'industries'){
            setShowCapabilitiesList(false)
            setShowIndustriesList(!showIndustriesList)
        }
        

    //   setShowSubMenu(!showSubMenu)
    //   setSelectedSubMenu(list)
    }

    const closeNavDropdowns = () => {
            setShowCapabilitiesList(false)
            setShowIndustriesList(false)
    }

    return (
        <>
        <div className='main-nav-wrapper-desktop' style={{background: 'none'}}>  
        
            <div className='nav-list-group' >
                {showCapabilitiesList ? <a href='/capabilities'>Capabilities</a> : <div style={{color: showCapabilitiesList ? 'gray' : NavFontColor }} onClick={() => fireSelectedSubMenu('capabilities')} >Capabilities</div>}
                

                {showIndustriesList ? <a href='/industries'>Industries</a> : <div style={{color: showIndustriesList ? 'gray' : NavFontColor }} onClick={() => fireSelectedSubMenu('industries')}> Industries</div>}
                
            </div>

            <div className='centered-logo'>
               <a href='/'> <img src={incLabLogo} /> </a>
            </div>

            <div className='nav-list-group' style={{color: NavFontColor }}>
                    <div> <a style={{color: NavFontColor }} href='/careers'> Careers </a> </div>
                    <div> <a style={{color: NavFontColor }} href='/work'> Work </a> </div>
            </div>

        </div>

        <motion.div className='sub-links-wrapper-desktop'
                variants={slideInSubMenuAnimation}
                initial='initial'
                animate={showCapabilitiesList  ? "animate" : "initial"}
                    transition={{ 
                        duration: .7,
                        type: 'spring',
                    }}
        >
        <div className='sub-menu-list'>
                    <div className='list-column'>
                        <div className='list-item'>
                           <a href='/capabilities/ai-technologies'>  AI & Machine Learning </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/emerging-tech-engineering'> Emerging Tech Engineering </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/rapid-prototyping'>  Rapid Prototyping </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/xr-collaboration'> XR Collaboration  </a>
                        </div>
                    </div>

                    <div className='list-column'>
                        <div className='list-item'>
                           <a href='/capabilities/disruptive-technologies'> Disruptive Technologies </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/user-centered-design'> User Centered Design </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/intelligent-systems-design'> Intelligent System Design </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/edge-technology'> Edge Technology  </a>
                        </div>
                    </div>

                    <div className='list-column'>
                        <div className='list-item'>
                           <a href='/capabilities/data-fusion'> Data Fusion </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/software-development'> Software Development </a>
                        </div>
                        <div className='list-item'>
                           <a href='/capabilities/advanced-3d'> Advanced 3D </a>
                        </div>
                        <div className='list-item'>
                          <a href='/capabilities/digital-engineering'> Digital Engineering </a>
                        </div>
                    </div>
        </div>
        </motion.div>


        <motion.div className='sub-links-wrapper-desktop'
                variants={slideInSubMenuAnimation}
                initial='initial'
                animate={showIndustriesList  ? "animate" : "initial"}
                    transition={{ 
                        duration: .7,
                        type: 'spring',
                    }}
        >
        <div className='sub-menu-list'>
                    <div className='list-column'>
                        <div className='list-item'>
                           <a href='/industries/defense'> Defense </a>
                        </div>
                        <div className='list-item'>
                           <a href='/industries/consumer-goods'> Consumer Goods </a>
                        </div>
                        <div className='list-item'>
                          <a href='/industries/health-and-life-sciences'> Health & Life Sciences </a>
                        </div>
                    </div>

                    <div className='list-column'>
                        <div className='list-item'>
                           <a href='/industries/intelligence'> Intelligence </a>
                        </div>
                        <div className='list-item'>
                           <a href='/industries/cybersecurity'> Cybersecurity</a>
                        </div>
                        <div className='list-item'>
                           <a href='/industries/consumer-electronics'>Consumer Electronics </a>
                        </div>
                    </div>

                    <div className='list-column'>
                        <div className='list-item'>
                           <a href='/industries/aerospace'> Aerospace </a>
                        </div>
                        <div className='list-item'>
                          <a href='/industries/transportation'>Transportation </a>
                        </div>
                        <div className='list-item'>
                           <a href='/industries/finance'>Finance </a>
                        </div>
                    </div>
        </div>
        </motion.div>

        
        <div className='overlay' onClick={() => closeNavDropdowns()} style={{display: showCapabilitiesList || showIndustriesList ? 'block' : 'none'  }}></div>


        </>
    )
}



export default DesktopNav
